window.parseToHTML = (str, head = false, callback) => {
	// WARNING: String should contain a root tag element,
	// siblings must be manage in different calls
	const doc = new DOMParser().parseFromString(str, 'text/html')
	const tag = !head ? doc.body.firstChild : doc.head
	if(!!callback) callback(tag)
	return tag
}

window.scrollToId = (id, padding = false) => {
	const element = document.getElementById(id)

  if (element) {
		if(!padding) { 
			window.scrollTo({ top: 0, behavior: 'auto' })
			setTimeout(() => { element.scrollIntoView({ behavior: 'smooth' }) }, 200)
		} else {
			const elementPosition = element.getBoundingClientRect().top
			const positionWidthPadding = elementPosition - padding;
			window.scrollTo({
				top:  positionWidthPadding > 0 ? positionWidthPadding : 0,
				behavior: 'smooth'
	 		})
		}
	}
}

window.checkUrl = () => {
  const hash = window.location.hash
  if(hash.length > 0) scrollToId(hash.split('#')[1])
}

window.checkInnerLinks = () => {
  const links = document.querySelectorAll('a[href="^#"]')
  for(let link of links) {
    link.addEventListener('click', () => {
      scrollToId(link.href.split('#')[1])
    })
  }
}

window.goToProject = () => {
	const project = window.location.pathname
	if(project) scrollToId(project, 20)
}

window.sketchWidth = (cols) => {
	const desktop = window.matchMedia("(min-width: 768px)").matches
	const colWidth = !desktop ? 52 : 102
	const gutter = !desktop ? (414-(colWidth*6))/7 : (1440-(colWidth*12))/13
	return colWidth * cols + (gutter * (cols - 1))
}

window.sliderHeight = (slider) => {
	const desktop  = window.matchMedia("(min-width: 768px)").matches
	const height  = parseInt(slider.dataset.leadHeight)
	const width   = parseInt(slider.dataset.leadWidth)
	const dataCol = parseInt(slider.parentElement.parentElement.dataset.col)

	const cols 	  = desktop ? dataCol : dataCol < 5 ? 4 : dataCol < 8 ? 5 : 6
	const finalCols = sketchWidth(cols)
	const pxHeight = height * finalCols / width
	const relativeHeight = 100 * pxHeight / (!desktop ? 414 : 1440)
	slider.style.height = `${relativeHeight}vw`
}

window.updateSliders = () => {
	const sliders = document.getElementsByClassName('slides-list');
	for(let slider of sliders) { 
		const dd = slider.parentElement.parentElement
		dd.classList.remove('check-single-row')
		sliderHeight(slider)
		setTimeout(() => {
			dd.classList.add('check-single-row')
		}, 350);
	}
}