import 'babel-polyfill' // Webpack needs
import Bowser from 'bowser'

////////////////////// HELPERS //////////////////////////
/////////////////////////////////////////////////////////
import '../app/js/helpers/HandyFunctions'
/////////////////// CONTENT LOAD ////////////////////////
/////////////////////////////////////////////////////////
updateSliders()

document.addEventListener('DOMContentLoaded', () => {
	const html = document.getElementsByTagName('html')[0]
	const data = Bowser.getParser(window.navigator.userAgent)

	html.dataset.browser = data.parsedResult.browser.name.toLowerCase()
	html.dataset.device  = data.parsedResult.platform.type.toLowerCase()
	html.dataset.os      = data.parsedResult.os.name.toLowerCase()

	const link = document.getElementById('link--switch-wraper').firstElementChild
	link.classList[link.getAttribute('href') === '/' ? 'add' : 'remove']('prevent')

	const flickityOptions = {
		draggable: false,
		fade: true,
		lazyLoad: true,
		pageDots: false,
		prevNextButtons: true,
		setGallerySize: false,
		wrapAround: true
	}

	const updatePageTitleAndDescription = (element) => {
		const dd = element.parentElement.parentElement
		document.title = dd.dataset.seoTitle
		const description = document.querySelector("meta[name='description']")
		if(description) description.content = dd.dataset.seoDescription
		window.history.pushState('', '', dd.id)
	}

	// ¿ HACK FIX for zooming ?
	Flickity.prototype._touchActionValue = 'pan-y pinch-zoom';
	const sliders = document.getElementsByClassName('slides-list')
	for(let slider of sliders) {
		const flkty = new Flickity(slider, flickityOptions)
		// appendListener(slider, flkty)
		flkty.on('change', function(index) { updatePageTitleAndDescription(slider) })

		const images = slider.getElementsByClassName('img')
		for(let img of images) {
			img.addEventListener('click', () => { flkty.next() })
		}
		const textWrapper = slider.getElementsByClassName('text-wrapper')[0]
		if(textWrapper) textWrapper.addEventListener('click', () => { flkty.next() })

		const dd = slider.parentElement.parentElement
		dd.style.order = Math.floor(Math.random() * 60)
	}

	setTimeout(() => {
		goToProject()
	}, 350)

	// const logo = document.getElementsByClassName('logo')[0]
	// logo.addEventListener('click', (e) => {
	// 	if(!window.location.href.includes('com/info')) {
	// 		e.preventDefault()
	// 		scrollToId('main-top-page')
	// 	}
	// })
})
////////////////////// PAGE LOAD /////////////////////////
/////////////////////////////////////////////////////////
window.addEventListener('load', () => {
  checkUrl()
	checkInnerLinks()

	setTimeout(() => {
		const buttons = document.getElementsByClassName('flickity-button')
		for(let button of buttons) {
			button.addEventListener('contextmenu', (e) => { e.preventDefault() })
		}
	}, 1000)
})